var render = function render(){var _vm=this,_c=_vm._self._c;return _c('YLayoutBodyMenu',{attrs:{"menu":[
    {title:'商品管理',path:'/shop/plugin/scan-goods/goods'},
    {title:'订单记录',path:'/shop/plugin/scan-goods/order'},
    {title:'核销门店',path:'/shop/plugin/scan-goods/store'},
    {title:'核销记录',path:'/shop/plugin/scan-goods/cashier-log'},
    // {title:'核销员',path:'/shop/plugin/scan-goods/clerk'},
]}},[_c('router-view')],1)
}
var staticRenderFns = []

export { render, staticRenderFns }