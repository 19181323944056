<template>
   <YLayoutBodyMenu :menu="[
       {title:'商品管理',path:'/shop/plugin/scan-goods/goods'},
       {title:'订单记录',path:'/shop/plugin/scan-goods/order'},
       {title:'核销门店',path:'/shop/plugin/scan-goods/store'},
       {title:'核销记录',path:'/shop/plugin/scan-goods/cashier-log'},
       // {title:'核销员',path:'/shop/plugin/scan-goods/clerk'},
   ]">
     <router-view/>
   </YLayoutBodyMenu>
</template>

<script>
import YLayoutBodyMenu from "@/components/layouts/YLayoutBodyMenu";
export default {
  name: "index",
  components: {YLayoutBodyMenu},
}
</script>

<style scoped>

</style>